import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import {
  Create,
  CreateProps,
  DateInput,
  FormDataConsumer,
  Record,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { InputAdornment } from '@material-ui/core'
import { apiUrl } from '../../../components/AppDataProvider'
import AutocompleteArrayInput from '../../../components/Inputs/AutocompleteArrayInput'
import { mainTheme } from '../../../utils/mainTheme'
import { dateToCorrectISOString } from '../../../utils/utils'
import httpClient from '../../../components/CustomHttpClient'
import { OptionType } from '../../../components/CustomAutocomplete'
import CouponsInput from '../../../components/Inputs/CouponsInput'
import { IVendorPromotion, IVoucher } from '@vacinas-net/shared'
import ClinicMap from '../../../components/ClinicMap'

const CampaignCreate = (props: CreateProps) => {
  const [promotions, setPromotions] = useState<
    { vendorId: string; name: string }[]
  >([])
  const transform = (data: Record) => {
    const startDate = dateToCorrectISOString(data.dateRange.startDate)
    const endDate = dateToCorrectISOString(data.dateRange.endDate)

    const isHandledByJenner = data.handler === 'jenner'

    const relatedUsers =
      data.relatedUsers?.map((user: OptionType) => {
        return {
          _id: user.value,
          email: user.label,
        }
      }) || undefined

    const commercialConditions =
      data.commercialConditions?.map((condition: OptionType) => {
        return {
          _id: condition.value,
          name: condition.label,
        }
      }) || undefined

    const vouchers: IVoucher[] | undefined =
      data.vouchers && !isHandledByJenner
        ? String(data.vouchers)
            .split('\n')
            .filter((redemptionCode) => !!redemptionCode)
            .map((redemptionCode) => ({
              redemptionCode: redemptionCode,
            }))
        : undefined

    const promotions: IVendorPromotion[] | undefined =
      data.promotions && !isHandledByJenner
        ? data.promotions.map((promotion: string) => JSON.parse(promotion))
        : undefined

    const productPriceInCents = data.productPriceInCents
      ? parseFloat(data.productPriceInCents) * 100
      : undefined

    const coupons =
      data.coupons && !isHandledByJenner ? data.coupons : undefined

    return {
      ...data,
      dateRange: {
        startDate,
        endDate,
      },
      relatedUsers,
      commercialConditions,
      vouchers,
      promotions,
      productPriceInCents,
      coupons,
    }
  }

  const fetchPromotions = async () => {
    return httpClient(`${apiUrl}/campaign/external/promotions`, {
      method: 'get',
    }).then((res) => setPromotions(res.json))
  }

  useEffect(() => {
    fetchPromotions()
  }, [])

  return (
    <Create transform={transform} {...props}>
      <SimpleForm submitOnEnter={false}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ marginBottom: mainTheme.spacing(1) }}
            variant="h1"
          >
            Nova campanha
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            <ClinicMap />
          </div>
        </div>
        <br />
        <Typography style={{ marginBottom: mainTheme.spacing(1) }} variant="h2">
          Tipo de campanha
        </Typography>
        <SelectInput
          defaultValue="jenner"
          source="handler"
          label="Tipo"
          variant="outlined"
          choices={[
            {
              id: 'jenner',
              name: 'Jenner',
            },
            {
              id: 'vtex',
              name: 'VTEX',
            },
          ]}
          style={{ width: 200 }}
          validate={required()}
        />

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div>
            <Typography
              style={{
                marginTop: mainTheme.spacing(2),
                marginBottom: mainTheme.spacing(2),
              }}
              variant="h2"
            >
              Dados base
            </Typography>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <TextInput
                color="primary"
                variant="outlined"
                label="Nome da campanha"
                source="name"
                style={{ marginRight: mainTheme.spacing(1) }}
                validate={required()}
              />
              <DateInput
                label="Data de início"
                source="dateRange.startDate"
                variant="outlined"
                style={{ marginRight: mainTheme.spacing(1) }}
                validate={required()}
              />
              <DateInput
                label="Data de fim"
                source="dateRange.endDate"
                variant="outlined"
                validate={required()}
              />
            </div>

            <FormDataConsumer>
              {() => {
                return (
                  <div>
                    <Typography
                      style={{
                        marginTop: mainTheme.spacing(2),
                        marginBottom: mainTheme.spacing(2),
                      }}
                      variant="h2"
                    >
                      Preço negociado
                    </Typography>
                    <TextInput
                      color="primary"
                      variant="outlined"
                      label="Preço"
                      source="productPriceInCents"
                      style={{
                        marginRight: mainTheme.spacing(1),
                        maxWidth: 200,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                      type="number"
                    />
                  </div>
                )
              }}
            </FormDataConsumer>
            <AutocompleteArrayInput
              resource="user"
              resourceName="Usuários"
              source="relatedUsers"
              optionTypeLabel="email"
              optionTypeValue="_id"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <FormDataConsumer>
                {({ formData }) => {
                  return (
                    formData.handler === 'vtex' && (
                      <>
                        <Typography
                          style={{
                            marginTop: mainTheme.spacing(1),
                            marginBottom: mainTheme.spacing(1),
                          }}
                          variant="h2"
                        >
                          Promoções
                        </Typography>
                        <SelectArrayInput
                          label="Promoções"
                          variant="outlined"
                          source="promotions"
                          optionText="name"
                          optionValue="data"
                          choices={promotions.map((promotion) => {
                            return {
                              id: promotion.vendorId,
                              name: promotion.name,
                              data: JSON.stringify({
                                vendorId: promotion.vendorId,
                                name: promotion.name,
                              }),
                            }
                          })}
                        />
                        <CouponsInput source="coupons" />
                        <Typography
                          style={{
                            marginTop: mainTheme.spacing(1),
                            marginBottom: mainTheme.spacing(1),
                          }}
                          variant="h2"
                        >
                          Vales
                        </Typography>
                        <TextInput
                          multiline={true}
                          rows={5}
                          source="vouchers"
                          label="Vales"
                          variant="outlined"
                          style={{ width: 400 + mainTheme.spacing(1) }}
                        />
                      </>
                    )
                  )
                }}
              </FormDataConsumer>

              <AutocompleteArrayInput
                resource="commercial-condition"
                resourceName="Condições comerciais"
                source="commercialConditions"
                optionTypeLabel="name"
                optionTypeValue="_id"
              />
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  )
}

export default CampaignCreate
