import {
  ClinicAvailableByDistance,
  IPayment,
  RequestOrderBatchDTO,
} from '@vacinas-net/shared'
import debounce from 'lodash/debounce'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNotify, useRedirect } from 'react-admin'
import { apiUrl } from '../AppDataProvider'
import customHttpClient from '../CustomHttpClient'
import { CreateOrderDTO } from '@vacinas-net/shared'
import { OptionType } from '../CustomAutocomplete'
import { formatISO, setHours, setMinutes } from 'date-fns'
import { fetchProductsPriceWithFiltersRequest } from './orderContextUtils'

export interface CartProduct {
  skuId: string
  name?: string
  priceInCents: number
  discountedPriceInCents: number
  quantity: number
}

export type OrderCreateType = {
  fetchingClinicDisponibility: boolean
  clinicDisponibility: ClinicAvailableByDistance[]
  removePayment: (position: number) => void
  getTotalOrderValue: number
  getTotalDiscountValue: number
  getTotalCartValue: number
  getTotalPaymentValue: number
  productsArray: CartProduct[]
  searchedOrderProductsArray: {
    skuId: string
    name: string
  }[]
  setSearchedOrderProductsArray: Dispatch<
    SetStateAction<
      {
        skuId: string
        name: string
      }[]
    >
  >
  zipCode: string
  setZipcode: Dispatch<SetStateAction<string>>
  coupon: string
  setCoupon: Dispatch<SetStateAction<string>>
  removeItemFromCart: (skuId: string) => void
  decreaseItemQuantity: (skuId: string) => void
  upgradeItemQuantity: (skuId: string) => void
  editItemDiscountedPrince: (
    skuId: string,
    discountedPriceInCents: number
  ) => void
  payments: IPayment[]
  addPayment: (payment: IPayment) => void
  name: string
  setName: Dispatch<SetStateAction<string>>
  cpf: string
  setCpf: Dispatch<SetStateAction<string>>
  cnpj: string
  setCnpj: Dispatch<SetStateAction<string>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  phone: string
  setPhone: Dispatch<SetStateAction<string>>
  createOrder: () => void
  loading: boolean
  loadingTable: boolean
  createMany?: boolean
  toggleCreateMany: () => void
  campaign: OptionType | null
  setCampaign: Dispatch<SetStateAction<OptionType | null>>
  clinic: OptionType | null
  setClinic: Dispatch<SetStateAction<OptionType | null>>
  pickupPointName: string
  setPickupPointName: Dispatch<SetStateAction<string>>
  pickupPointId: string
  setPickupPointId: Dispatch<SetStateAction<string>>
  startDate: string
  setStartDate: Dispatch<SetStateAction<string>>
  endDate: string
  setEndDate: Dispatch<SetStateAction<string>>
  shotDate: string
  setShotDate: Dispatch<SetStateAction<string>>
  expirationDate: string
  setExpirationDate: Dispatch<SetStateAction<string>>
  batch: string
  setBatch: Dispatch<SetStateAction<string>>
  dose: 'first' | 'second' | 'third' | 'booster' | 'anual' | undefined
  setDose: Dispatch<
    SetStateAction<
      'first' | 'second' | 'third' | 'booster' | 'anual' | undefined
    >
  >
  setFile: Dispatch<SetStateAction<File | undefined>>
  shouldRenderCreateManyButton: () => boolean
  createManyOrders: () => void
}

export const OrderCreateContext = createContext<OrderCreateType | null>(null) //Add LocalStorage Values ?

export const OrderCreateProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const redirect = useRedirect()
  const notify = useNotify()
  const [productsArray, setProductsArray] = useState<CartProduct[]>([])
  const [searchedOrderProductsArray, setSearchedOrderProductsArray] = useState<
    {
      skuId: string
      name: string
    }[]
  >([])
  const [zipCode, setZipcode] = useState<string>('')
  const [coupon, setCoupon] = useState<string>('')
  const [payments, setPayments] = useState<IPayment[]>([])
  const [name, setName] = useState<string>('')
  const [cpf, setCpf] = useState<string>('')
  const [cnpj, setCnpj] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [loadingTable, setLoadingTable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [createMany, setCreateMany] = useState(false)
  const [campaign, setCampaign] = useState<OptionType | null>(null)
  const [clinic, setClinic] = useState<OptionType | null>(null)
  const [pickupPointName, setPickupPointName] = useState('')
  const [pickupPointId, setPickupPointId] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [shotDate, setShotDate] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [batch, setBatch] = useState('')
  const [dose, setDose] =
    useState<'first' | 'second' | 'third' | 'booster' | 'anual' | undefined>()
  const [file, setFile] = useState<File>()
  const [clinicDisponibility, setClinicDisponibility] = useState<
    ClinicAvailableByDistance[]
  >([])
  const [fetchingClinicDisponibility, setFetchingClinicDisponibility] =
    useState(false)

  const fetchProductsPriceWithFilters = useMemo(
    () =>
      debounce(
        (
          productsToSearch: {
            skuId: string
            name: string
          }[],
          zipCode,
          coupon,
          currentProductsArray: CartProduct[]
        ) => {
          setLoadingTable(true)
          const params: URLSearchParams = new URLSearchParams()
          params.set(
            'skus',
            productsToSearch.map((product) => product.skuId).join(',')
          )
          zipCode && params.set('zipCode', zipCode.replace(/\D/g, ''))
          coupon && params.set('couponCode', coupon)

          fetchProductsPriceWithFiltersRequest(
            params,
            currentProductsArray,
            productsToSearch
          )
            .then((requestResponse) => {
              setProductsArray(requestResponse)
            })
            .catch((error) => {
              notify(`Erro ao obter os produtos: ${error}`, 'error')
              setProductsArray(
                productsToSearch.map((product) => {
                  const matchedProduct = currentProductsArray.find(
                    (p) => p.skuId === product.skuId
                  )
                  return {
                    ...product,
                    quantity: matchedProduct ? matchedProduct.quantity : 1,
                    priceInCents: matchedProduct
                      ? matchedProduct.priceInCents
                      : 0,
                    discountedPriceInCents: matchedProduct
                      ? matchedProduct.discountedPriceInCents
                      : 0,
                  }
                })
              )
            })
            .finally(() => setLoadingTable(false))
        },
        1000
      ),
    []
  )

  const removeItemFromCart = (skuId: string) => {
    setProductsArray(productsArray.filter((product) => product.skuId !== skuId))
    setSearchedOrderProductsArray(
      searchedOrderProductsArray.filter((product) => product.skuId !== skuId)
    )
  }

  const getProductQuantityBySku = (
    productsArrayCopy: CartProduct[],
    skuId: string
  ) => {
    return productsArrayCopy[
      productsArray.findIndex((item) => item.skuId === skuId)
    ].quantity
  }

  const upgradeItemQuantity = (skuId: string) => {
    const productArrayCopy = [...productsArray]
    productArrayCopy[
      productsArray.findIndex((item) => item.skuId === skuId)
    ].quantity += 1

    setProductsArray(productArrayCopy)
  }

  const decreaseItemQuantity = (skuId: string) => {
    const productArrayCopy = [...productsArray]
    if (getProductQuantityBySku(productArrayCopy, skuId) > 1)
      productArrayCopy[
        productsArray.findIndex((item) => item.skuId === skuId)
      ].quantity -= 1
    setProductsArray(productArrayCopy)
  }

  const editItemDiscountedPrince = (
    skuId: string,
    discountedPriceInCents: number
  ) => {
    const productArrayCopy = [...productsArray]
    productArrayCopy[
      productsArray.findIndex((item) => item.skuId === skuId)
    ].discountedPriceInCents = discountedPriceInCents
    setProductsArray(productArrayCopy)
  }

  const addPayment = (payment: IPayment) => {
    setPayments([...payments, payment])
  }

  const getTotalPaymentValue = useMemo(() => {
    const initialValuesInCents = 0
    return payments.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.referencePriceInCents!,
      initialValuesInCents
    )
  }, [payments])

  const removePayment = (postion: number) => {
    const paymentsCopy = [...payments]
    paymentsCopy.splice(postion, 1)
    setPayments(paymentsCopy)
  }

  const getTotalCartValue = useMemo(() => {
    const initialValuesInCents = 0
    return productsArray.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.priceInCents! * currentValue.quantity,
      initialValuesInCents
    )
  }, [productsArray])

  const getTotalDiscountValue = useMemo(() => {
    const initialValuesInCents = 0
    return (
      getTotalCartValue -
      productsArray.reduce(
        (previousValue, currentValue) =>
          previousValue +
          currentValue.discountedPriceInCents * currentValue.quantity,
        initialValuesInCents
      )
    )
  }, [productsArray])

  const getTotalOrderValue = useMemo(() => {
    return getTotalCartValue - (getTotalPaymentValue + getTotalDiscountValue)
  }, [getTotalCartValue, getTotalPaymentValue, getTotalDiscountValue])

  const createOrder = async () => {
    setLoading(true)
    const parsedOrder: CreateOrderDTO = {
      customer: {
        document: cpf ? cpf.replace(/\D/g, '') : cnpj.replace(/\D/g, ''),
        email: email,
        name: name,
        phone: `+55${phone.replace(/\D/g, '')}`,
      },
      couponCode: coupon || undefined,
      zipCode: zipCode || undefined,
      payments: payments,
      products: productsArray.map((product) => ({
        skuId: product.skuId,
        valueInCents: product.priceInCents,
        quantity: product.quantity,
        discountInCents: product.priceInCents - product.discountedPriceInCents,
      })),
    }

    customHttpClient(`${apiUrl}/order`, {
      method: 'post',
      body: JSON.stringify(parsedOrder),
    })
      .then((res) => {
        setLoading(false)
        redirect(`/order/${res.json._id}/show`)
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
        notify(`Erro ao criar pedido: ${error}`, 'error')
      })
  }

  const toggleCreateMany = () => {
    setCreateMany(!createMany)
    setCoupon('')
    setZipcode('')
  }

  const shouldRenderCreateManyButton = () => {
    if (
      productsArray.length > 0 &&
      campaign &&
      clinic &&
      startDate &&
      pickupPointId &&
      shotDate &&
      dose &&
      batch &&
      expirationDate &&
      file
    ) {
      return true
    }
    return false
  }

  const createManyOrders = () => {
    setLoading(true)
    let parsedEndDate = new Date(startDate)
    const [hours, minutes] = endDate.split(':')
    parsedEndDate = setHours(parsedEndDate, parseFloat(hours))
    parsedEndDate = setMinutes(parsedEndDate, parseFloat(minutes))
    const parsedOrder: RequestOrderBatchDTO = {
      products: productsArray.map((product) => ({
        skuId: product.skuId,
        valueInCents: product.priceInCents,
        quantity: product.quantity,
        discountInCents: product.priceInCents - product.discountedPriceInCents,
      })),
      campaignId: String(campaign?.value),
      clinicId: String(clinic?.value),
      pickupPointId: pickupPointId,
      deliveryWindow: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        startDate: formatISO(new Date(startDate)),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        endDate: formatISO(parsedEndDate),
      },
      applicationData: {
        batchNumber: batch,
        dose: dose,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        expirationDate: formatISO(new Date(expirationDate)),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        shotDate: formatISO(new Date(shotDate)),
      },
    }

    const formData = new FormData()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    formData.append('file', file)

    formData.append('data', JSON.stringify(parsedOrder))

    customHttpClient(`${apiUrl}/order-batch`, {
      method: 'post',
      body: formData,
    })
      .then(() => {
        notify(
          'Processando... \n\nUm e-mail será enviado para você quando o processamento estiver completo.',
          'success'
        )
        redirect(`/order`)
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao criar pedido: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const checkProductsAvaliability = () => {
    setFetchingClinicDisponibility(true)
    customHttpClient(
      `${apiUrl}/clinic/${zipCode}/distanceRangeByProduct?${productsArray
        .map((product) => {
          return `sku=${product.skuId}`
        })
        .join('&')}`
    )
      .then((res) => {
        setClinicDisponibility(res.json)
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao checar disponibilidade das clínicas: ${error}`, 'error')
      })
      .finally(() => {
        setFetchingClinicDisponibility(false)
      })
  }

  useEffect(() => {
    if (searchedOrderProductsArray.length < 1) return
    fetchProductsPriceWithFilters(
      searchedOrderProductsArray,
      zipCode,
      coupon,
      productsArray
    )
  }, [searchedOrderProductsArray, zipCode, coupon])

  useEffect(() => {
    if (productsArray.length > 0 && zipCode) {
      checkProductsAvaliability()
    }
  }, [productsArray])

  return (
    <OrderCreateContext.Provider
      value={{
        fetchingClinicDisponibility,
        clinicDisponibility,
        createManyOrders,
        shouldRenderCreateManyButton,
        setFile,
        dose,
        setDose,
        batch,
        setBatch,
        expirationDate,
        setExpirationDate,
        shotDate,
        setShotDate,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        pickupPointName,
        setPickupPointName,
        pickupPointId,
        setPickupPointId,
        clinic,
        setClinic,
        campaign,
        setCampaign,
        cnpj,
        setCnpj,
        createMany,
        toggleCreateMany,
        loadingTable,
        loading,
        createOrder,
        getTotalOrderValue,
        getTotalDiscountValue,
        getTotalCartValue,
        removePayment,
        getTotalPaymentValue,
        payments,
        addPayment,
        editItemDiscountedPrince,
        upgradeItemQuantity,
        decreaseItemQuantity,
        removeItemFromCart,
        productsArray,
        searchedOrderProductsArray,
        setSearchedOrderProductsArray,
        zipCode,
        setZipcode,
        coupon,
        setCoupon,
        name,
        setName,
        cpf,
        setCpf,
        email,
        setEmail,
        phone,
        setPhone,
      }}
    >
      {children}
    </OrderCreateContext.Provider>
  )
}
